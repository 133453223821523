import { DialogContent, DialogOverlay } from '@reach/dialog';
import { Portal } from '@reach/portal';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import FocusLock from 'react-focus-lock';
import { RemoveScroll } from 'react-remove-scroll';
import { Transition } from 'react-transition-group';
import {
  ENTERED,
  ENTERING,
  EXITED,
  EXITING,
} from 'react-transition-group/Transition';
import { useContextSelector, useContext } from 'use-context-selector';
import { ModalTemplate } from 'components/overlay/ModalTemplate';
import styles from 'components/overlay/Overlay.module.scss';
import { OverlayContext } from 'components/overlay/OverlayContext';
import type { OverlayProps } from 'components/overlay/OverlayLazy';
import IconClose from 'icons/misc/close.svg';
import { $blockClass } from 'utils/helpers';

export const Overlay = ({
  blockClass = '',
  title,
  close,
  children,
  footer,
  type = 'modal',
  direction = 'right',
  template = true,
  namespace,
  wide = false,
  persistOnRouteChange = false,
  dangerouslyBypassFocusLock = false,
  useReachUi = true,
  customHandleRouteChange,
}: OverlayProps) => {
  const timing = 300;
  const animationTimer = useRef<NodeJS.Timeout | null>(null);
  const [transition, setTransition] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const nodeRef = useRef(null);
  const overlayRef = useRef(null);
  const router = useRouter();

  /* Ref for custom overlay click tracking */
  const mouseDownTarget = useRef<EventTarget | null>(null);
  const handleCustomMouseDown = (event: React.MouseEvent) => {
    mouseDownTarget.current = event.target;
  };

  const handleCustomClick = (event: React.MouseEvent) => {
    if (mouseDownTarget.current === event.target) {
      event.stopPropagation();
      closeAction(namespace);
    }
  };

  const handleCustomKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      event.stopPropagation();
      closeAction(namespace);
    }
  };

  const { close: closeAction } = useContext(OverlayContext);

  const isActive = useContextSelector(
    OverlayContext,
    ({ active }) => active[0] === namespace
  );

  /**
   * Closes the overlay on route change.
   */
  const defaultHandleRouteChange = () => closeAction(namespace);

  const handleRouteChange = customHandleRouteChange
    ? customHandleRouteChange
    : defaultHandleRouteChange;

  useEffect(() => {
    if (isActive) {
      const openingTiming = namespace === 'menuDrawer' ? timing : 0;

      setIsOpen(true);
      setTimeout(() => {
        setTransition(true);
      }, openingTiming);
      animationTimer.current && clearTimeout(animationTimer.current);
    } else {
      setTransition(false);
      animationTimer.current = setTimeout(() => {
        setIsOpen(false);
      }, timing);
    }

    if (persistOnRouteChange) {
      return;
    }

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [
    isActive,
    isOpen,
    transition,
    router.events,
    closeAction,
    persistOnRouteChange,
    namespace,
    handleRouteChange,
  ]);

  const [className] = useState(
    `${type === 'modal' ? 'fade' : 'slide'}-${direction}`
  );

  if (useReachUi) {
    return (
      <Transition nodeRef={nodeRef} in={transition} timeout={timing}>
        {(transitionStatus) => (
          <DialogOverlay
            dangerouslyBypassFocusLock={dangerouslyBypassFocusLock}
            isOpen={isOpen}
            allowPinchZoom={true}
            onDismiss={() => {
              closeAction(namespace);
            }}
            className={clsx(
              styles[$blockClass(blockClass)],
              styles['overlay__reach-overlay'],
              styles[$blockClass(blockClass, 'reach-overlay')],
              styles[$blockClass(type, 'reach-overlay')],
              direction && styles[`${type}--${direction}`],
              {
                'fade-enter-active':
                  transitionStatus === EXITED || transitionStatus === ENTERING,
                'fade-enter-from':
                  transitionStatus === EXITED || transitionStatus === ENTERING,
                'fade-enter-to': transitionStatus === ENTERING,
                'fade-leave-active':
                  transitionStatus === ENTERED || transitionStatus === EXITING,
                'fade-leave-to': transitionStatus === EXITING,
              }
            )}
            ref={nodeRef}
            data-transitioned={transitionStatus === ENTERED}
          >
            <DialogContent
              aria-label={namespace}
              className={clsx(
                'critical-hide',
                styles.overlay,
                styles[type],
                wide && styles[`${type}--wide`],
                direction && styles[`${type}--${direction}`],
                {
                  [`${className}-enter-active`]:
                    transitionStatus === EXITED ||
                    transitionStatus === ENTERING,
                  [`${className}-enter-from`]:
                    transitionStatus === EXITED ||
                    transitionStatus === ENTERING,
                  [`${className}-enter-to`]: transitionStatus === ENTERING,
                  [`${className}-leave-active`]:
                    transitionStatus === ENTERED ||
                    transitionStatus === EXITING,
                  [`${className}-leave-to`]: transitionStatus === EXITING,
                }
              )}
              data-mobile-drawer={type === 'mobile-drawer' ? '' : undefined}
              ref={overlayRef}
            >
              {template ? (
                <ModalTemplate
                  title={title}
                  close={close ?? <IconClose />}
                  blockClass={blockClass}
                  type={type}
                  namespace={namespace}
                  footer={footer}
                  overlayRef={overlayRef}
                >
                  {children}
                </ModalTemplate>
              ) : (
                <div
                  className={[
                    styles.overlay__container,
                    styles[$blockClass(blockClass, 'container')],
                    styles[$blockClass(type, 'container')],
                  ].join(' ')}
                >
                  {children}
                </div>
              )}
            </DialogContent>
          </DialogOverlay>
        )}
      </Transition>
    );
  }

  /**
   * Output overlay without ReachUI components.
   */
  /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-element-interactions */
  return (
    <Transition nodeRef={nodeRef} in={transition} timeout={timing}>
      {(transitionStatus) => (
        <Portal>
          <FocusLock disabled={!isOpen} returnFocus>
            <RemoveScroll enabled={isOpen}>
              <div
                className={clsx(
                  styles[$blockClass(blockClass)],
                  styles['overlay__reach-overlay'],
                  styles['custom-overlay__reach-overlay'],
                  styles[$blockClass(blockClass, 'reach-overlay')],
                  styles[$blockClass(type, 'reach-overlay')],
                  {
                    'fade-enter-active':
                      transitionStatus === EXITED ||
                      transitionStatus === ENTERING,
                    'fade-enter-from':
                      transitionStatus === EXITED ||
                      transitionStatus === ENTERING,
                    'fade-enter-to': transitionStatus === ENTERING,
                    'fade-leave-active':
                      transitionStatus === ENTERED ||
                      transitionStatus === EXITING,
                    'fade-leave-to': transitionStatus === EXITING,
                  }
                )}
                data-custom-reach-overlay
                data-reach-dialog-overlay
                data-reach-dialog-inner
                data-state={isOpen ? 'open' : 'closed'}
                data-transition={transition}
                onClick={handleCustomClick}
                onMouseDown={handleCustomMouseDown}
                onKeyDown={handleCustomKeyDown}
                ref={nodeRef}
              >
                <div
                  aria-modal={true}
                  role="dialog"
                  tabIndex={-1}
                  aria-label={namespace}
                  className={clsx(
                    'critical-hide',
                    styles.overlay,
                    styles[type],
                    wide && styles[`${type}--wide`],
                    direction && styles[`${type}--${direction}`],
                    {
                      [`${className}-enter-active`]:
                        transitionStatus === EXITED ||
                        transitionStatus === ENTERING,
                      [`${className}-enter-from`]:
                        transitionStatus === EXITED ||
                        transitionStatus === ENTERING,
                      [`${className}-enter-to`]: transitionStatus === ENTERING,
                      [`${className}-leave-active`]:
                        transitionStatus === ENTERED ||
                        transitionStatus === EXITING,
                      [`${className}-leave-to`]: transitionStatus === EXITING,
                    }
                  )}
                  data-custom-reach-content
                  data-reach-dialog-content
                  data-transitioned={transitionStatus === ENTERED}
                  data-state={isOpen ? 'open' : 'closed'}
                  data-mobile-drawer={type === 'mobile-drawer' ? '' : undefined}
                  onClick={(event) => event.stopPropagation()}
                  ref={overlayRef}
                >
                  {template ? (
                    <ModalTemplate
                      title={title}
                      close={close ?? <IconClose />}
                      blockClass={blockClass}
                      type={type}
                      namespace={namespace}
                      footer={footer}
                      overlayRef={overlayRef}
                    >
                      {children}
                    </ModalTemplate>
                  ) : (
                    <div
                      className={[
                        styles.overlay__container,
                        styles[$blockClass(blockClass, 'container')],
                        styles[$blockClass(type, 'container')],
                      ].join(' ')}
                    >
                      {children}
                    </div>
                  )}
                </div>
              </div>
            </RemoveScroll>
          </FocusLock>
        </Portal>
      )}
    </Transition>
  );
  /* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-element-interactions */
};

export default Overlay;
