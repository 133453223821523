import { i18n } from '@lingui/core';
import clsx from 'clsx';
import { isValidElement, type ReactNode, type RefObject } from 'react';
import { useContext } from 'use-context-selector';
import styles from 'components/overlay/Overlay.module.scss';
import type { modalType } from 'components/overlay/OverlayLazy';
import { $blockClass } from 'utils/helpers';
import { useMobileDragClose } from 'utils/hooks/useMobileDragClose';
import { OverlayContext } from './OverlayContext';

export const ModalTemplate = ({
  blockClass,
  type,
  title,
  close,
  children,
  namespace,
  footer,
  overlayRef,
}: {
  blockClass: string;
  type: modalType;
  title: ReactNode;
  close: ReactNode;
  children: ReactNode;
  namespace: string;
  footer?: ReactNode;
  overlayRef: RefObject<HTMLDivElement>;
}) => {
  const { close: closeAction } = useContext(OverlayContext);
  const { handleTouchStart, handleTouchMove, handleTouchEnd } =
    useMobileDragClose({ overlayRef, namespace, close: closeAction });

  return (
    <div
      className={[
        styles.overlay__container,
        styles[$blockClass(blockClass, 'container')],
        styles[$blockClass(type, 'container')],
      ].join(' ')}
    >
      <div
        className={[
          styles.overlay__header,
          styles[$blockClass(blockClass, 'header')],
          styles[$blockClass(type, 'header')],
        ].join(' ')}
      >
        {title && !isValidElement(title) && (
          <p
            className={[
              'text-utility-utility-desktop text-utility-utility-mobile',
              styles[$blockClass(blockClass, 'title')],
              styles[$blockClass(type, 'title')],
            ].join(' ')}
          >
            {title}
          </p>
        )}

        {isValidElement(title) && title}

        <button
          className={clsx(
            styles.overlay__handle,
            styles[$blockClass(blockClass, 'handle')],
            styles[$blockClass(type, 'handle')]
          )}
          aria-label={i18n._(
            /* i18n */ {
              id: 'accessibility.close',
              message: 'Close',
            }
          )}
          onClick={(event) => {
            event.stopPropagation();
            closeAction(namespace);
          }}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onTouchMove={handleTouchMove}
        ></button>

        {close && (
          <button
            className={[
              styles.overlay__close,
              styles[$blockClass(blockClass, 'close')],
              styles[$blockClass(type, 'close')],
            ].join(' ')}
            aria-label={i18n._(
              /* i18n */ {
                id: 'accessibility.close',
                message: 'Close',
              }
            )}
            onClick={(event) => {
              event.stopPropagation();
              closeAction(namespace);
            }}
          >
            {close}
          </button>
        )}
      </div>

      <div
        className={[
          styles.overlay__body,
          styles[$blockClass(blockClass, 'body')],
          styles[$blockClass(type, 'body')],
        ].join(' ')}
      >
        {children}
      </div>
      {footer && (
        <div
          className={[
            styles.overlay__footer,
            styles[$blockClass(blockClass, 'footer')],
            styles[$blockClass(type, 'footer')],
          ].join(' ')}
        >
          {footer}
        </div>
      )}
    </div>
  );
};
