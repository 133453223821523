import type { RefObject, TouchEvent } from 'react';
import { useState } from 'react';

export const useMobileDragClose = ({
  overlayRef,
  close,
  namespace,
}: {
  overlayRef: RefObject<HTMLDivElement>;
  close: (namespace: string) => void;
  namespace: string;
}) => {
  const [threshold, setThreshold] = useState(200);
  const [previousClientY, setPreviousClientY] = useState(0);
  const [transform, setTransform] = useState(0);

  /**
   * Handle first touch on modal handle.
   * @param {TouchEvent} event - Touch event.
   */
  const handleTouchStart = (event: TouchEvent): void => {
    if (!overlayRef?.current) return;

    const touches = event.changedTouches;

    setPreviousClientY(touches[0].clientY);

    const updatedThreshold = overlayRef.current?.offsetHeight * 0.2;
    setThreshold(updatedThreshold);
  };

  /**
   * Handle touch move on modal handle.
   * @param {TouchEvent} event - Touch event.
   */
  const handleTouchMove = (event: TouchEvent) => {
    if (!overlayRef?.current) return;
    const touches = event.changedTouches;

    let newTransform = touches[0].clientY - previousClientY;

    /**
     * Check to see if movement is upward.
     * - If the movement is upward reduce the movement creating drag.
     */
    if (Math.sign(transform) === -1) {
      newTransform = (Math.max(touches[0].clientY, 0) - previousClientY) / 3;
    }

    /**
     * Pass the movement to the transform of the element.
     */
    overlayRef.current.style.transform = `translateY(${newTransform}px)`;

    /**
     * Save transform to reference it in the touchEnd hanlder.
     */
    setTransform(newTransform);

    /**
     * If threshold is passed close overlay.
     */
    if (transform > threshold - 1) {
      close(namespace);

      /**
       * After build out animation has finished, reset position.
       */
      setTimeout(() => {
        if (!overlayRef.current) return;

        overlayRef.current.style.transform = 'translateY(0px)';
      }, 300);
    }
  };

  /**
   * Handle touch end on modal handle.
   */
  const handleTouchEnd = () => {
    if (!overlayRef?.current) return;

    if (transform < threshold) {
      overlayRef.current.style.transform = 'translateY(0px)';
    }
  };

  return {
    handleTouchEnd,
    handleTouchStart,
    handleTouchMove,
  };
};
